section.featured-news {
  padding: 70px 20px 90px;
  background: #363638;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.3);
  }

  h2 {
    margin: 0 0 20px;
    color: $featured-news-heading-color;
    font-weight: $regular;
    font-style: normal;
    font-size: 36px;

    @include medium-up {
      max-width: 1600px;
      margin: 0 auto 30px;
    }
  }

  .news-items {
    width: 100%;
    display: block;

    @include medium-up {
      display: flex;
      justify-content: space-between;
      flex-direction: inherit;
      max-width: 1600px;
      margin: 0 auto;
    }

    .news-item {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      vertical-align: top;
      background: white;
      margin: 15px 0;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, .15);
      padding: 20px;

      img{
        width: 100%;
      }

      @include medium-up {
        width: calc(33% - 40px);
        margin: 0 15px;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .news-item-image {
        position: relative;
        margin: -20px -20px 20px -20px;
        width: calc(100% + 40px);
      }

      .news-item-info {
        padding: 30px;

        h3 {
          margin: 0 0 10px;
          font-size: 30px;
          font-weight: $bold;

          a {
            color: $primary-color;
            text-decoration: none;
            line-height: 24px;
            border: none;

            &:hover,
            &:focus {
              color: $tertiary-color;
            }
          }
        }

        .news-item-date {
          font-size: 14px;
          font-weight: $bold;
          color: $news-date-color;
          letter-spacing: 1px;
        }

        .news-item-blurb {
          font-size: 18px;
          line-height: 24px;
          font-weight: $regular;
          color: $news-blurb-color
        }

        .news-item-read-more {

          a {
            color: $primary-color;
            text-decoration: none;
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 1px;

            &:hover,
            &:focus {
              color: $tertiary-color;
            }
          }
        }
      }
    }
  }

  .view-all-news {
    padding: 10px 0 0;
    max-width: 1600px;
    margin: auto;

    a {
      color: $news-view-all-color;
      text-decoration: none;
      font-weight: $regular;
      font-size: 24px;
      border: none;

      &:hover,
      &:focus {
        color: white;
      }
    }
  }
}